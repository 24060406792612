import { isEqual } from "lodash";

import { joinDatasetQueryPath } from "../hql/hqlNextUtils.js";

import {
  SemanticAwareColumn,
  SemanticAwareFieldGroup,
} from "./semanticTypes.js";

export function getColumnNameWithQueryPath(
  column: SemanticAwareColumn,
): string {
  if (column.queryPath == null || column.queryPath.length === 0) {
    return column.columnId;
  }
  return [joinDatasetQueryPath(column.queryPath), column.columnId].join(":");
}

export function findColumnInFieldGroups({
  columnId,
  fieldGroups,
  queryPath,
}: {
  columnId: string;
  queryPath: string[];
  fieldGroups: SemanticAwareFieldGroup[];
}): SemanticAwareColumn | undefined {
  return fieldGroups
    .find((group) => isEqual(group.queryPath, queryPath))
    ?.columns.find((c) => c.columnId === columnId);
}
export function getColumnFromColumnNameWithQueryPath(
  columnNameWithQueryPath: string,
  fieldGroups: SemanticAwareFieldGroup[],
): SemanticAwareColumn | undefined {
  if (fieldGroups.length === 0) {
    return undefined;
  }
  if (fieldGroups.length === 1) {
    return [...fieldGroups[0]!.columns, ...fieldGroups[0]!.measures].find(
      (column) => column.columnId === columnNameWithQueryPath,
    );
  }
  if (fieldGroups.length > 1) {
    const parts = columnNameWithQueryPath.split(":");

    const allGroups = fieldGroups.flatMap((group) => [
      group,
      ...group.children,
    ]);
    const groupNames = new Set(allGroups.map((group) => group.name));

    // If the column name only has a single part - then it doesn't have a query
    // path/is on the base dataset.
    const queryPath: string[] = [];
    // If there are multiple parts, we check below to see what parts correspond
    // to datasets (since a column with no query path could have a colon in it's
    // name).

    // Note - we could get some unexpected behavior here if a column name
    // with colon matches a group name...
    if (parts.length > 1) {
      while (parts.length > 1 && groupNames.has(parts[0] ?? "")) {
        const name = parts.shift()!;
        if (name) {
          queryPath.push(name);
        }
      }
    }

    const columnName = parts.join(":");

    // find matching group first
    const matchingGroup = allGroups.find((group) =>
      isEqual(group.queryPath, queryPath),
    );

    if (matchingGroup == null) {
      return undefined;
    }
    return [...matchingGroup.columns, ...matchingGroup.measures].find(
      (column) => column.columnId === columnName,
    );
  }
}
